@import '@jaramba-frontend/core/styles/variables';

.header {
    width: 100%;
    height: 6rem;
    background-color: $white;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $headerBoxShadow;
    position: relative;

    @screen md {
        height: 7rem;
    }
}
