@import '@jaramba-frontend/core/styles/mixins';
@import '@jaramba-frontend/core/styles/variables';

.tabButtons {
    display: flex;
    position: relative;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    .indicator {
        position: absolute;
        bottom: 0;
        height: 0.3rem;
        background-color: $primary;
        border-radius: 5px;
        @include gpuAcceleration;
        transition: transform 0.2s ease-out, width 0.2s ease-out;
    }
}

.tabButton {
    @extend .MikadoRegular;
    font-weight: 400;

    font-size: $text-base;
    line-height: 1.8rem;
    white-space: nowrap;

    cursor: pointer;
    padding: 1.1rem 0.3rem;
    opacity: 0.8;

    &:not(:last-child) {
        margin-right: 3rem;
    }

    &.active {
        @extend .MikadoBold;
        font-weight: 700;
        position: relative;

        color: $primary;
        opacity: 0.8;
    }
}
