@import 'variables';

html {
    box-sizing: border-box;
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: 'MikadoRegular', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: $grey;

    font-size: $text-base;

    background-color: $document-background;
}

// Headings
h1 {
    font-family: MikadoBold;
    font-weight: 700;

    font-size: $title;
    line-height: 4.8rem;

    padding-bottom: 2.5rem;

    @screen md {
        font-size: 5.6rem;
        line-height: 6.4rem;
    }
}

h2 {
    font-family: MikadoBold;
    font-weight: 700;

    font-size: $subtitle-large;
    line-height: 4rem;

    @screen md {
        font-size: $title;
        line-height: 4.8rem;
    }
}

h3 {
    font-family: MikadoBold;
    font-weight: 700;

    font-size: $text-large;
    line-height: 3.2rem;

    @screen md {
        font-size: $subtitle;
        line-height: 3.2rem;
    }
}

h4 {
    font-family: MikadoBold;
    font-weight: 700;

    font-size: $text-increased;
    line-height: 2.4rem;

    word-break: break-all;
}

// Paragraph

p {
    font-size: $text-increased;
    line-height: 2.8rem;

    &.font-small {
        font-size: $text-small;
        line-height: 2.3rem;
    }
}

// Links

a.button {
    display: inline-block;

    font-family: MikadoBold;

    font-size: $text-increased;
    line-height: 1.8rem;

    background-color: $primary;
    color: $white;
    padding: 1.6rem 3.7rem;

    border-radius: 100px;
}

.grid-section {
    @screen md {
        @apply grid gap-8;
    }

    .grid-item {
        @apply flex flex-col mb-12;
    }
}
