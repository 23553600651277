@import '@jaramba-frontend/core/styles/variables';

.root {
    @extend .MikadoBold;

    display: block;
    width: 100%;
    max-width: 27.5rem;
    min-width: fit-content;
    text-align: center;
    font-size: $text-base;
    line-height: 1.8rem;
    color: $white;
    margin: 1rem auto;
    background-color: $primary;
    padding: 1.4rem 2.4rem;
    border-radius: 100px;
    transition: opacity 0.1s ease;

    &:focus,
    &:hover {
        opacity: 0.8;
    }

    &.fullWidth {
        max-width: unset;
    }

    &:disabled,
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.outlined {
        color: $primary;
        border: 2px solid $primary;
        background-color: $white;

        margin: 1rem auto;
    }

    @screen md {
        display: inline-block;
        max-width: 16.8rem;
    }
}
