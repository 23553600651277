@import '@jaramba-frontend/core/styles/variables';
@import '@jaramba-frontend/core/styles/mixins';

.root {
    &:not(:last-child) {
        margin-bottom: 1rem;

        @screen md {
            margin-bottom: 1.6rem;
        }
    }

    @include gpuAcceleration;

    .label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.6rem;
        width: 100%;
        text-align: left;
        color: $grey;
        border: none;
        cursor: pointer;

        @extend .MikadoBold;
        font-weight: 700;
        font-size: $text-increased;
        line-height: 2.8rem;
    }

    .contentContainer {
        transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-radius: 0 0 $paperBorderRadius $paperBorderRadius;
    }

    .content {
        color: $grey;
        background-color: $white;
        padding: 0 1.6rem 1.6rem;
        font-size: $text-increased;
        line-height: 2.8rem;
        font-weight: 400;
        border-radius: 0 0 $paperBorderRadius $paperBorderRadius;
    }

    .chevronIcon {
        width: 2rem;
        height: 2rem;
        transition: transform 0.2s ease;
        margin-left: 1rem;

        &.reversed {
            transform: rotate(-180deg);
        }
    }
}
