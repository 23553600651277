@import '@jaramba-frontend/core/styles/variables';

.footer {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background-color: $beige;

    a {
        margin: 0 2.3rem;

        text-decoration: none;

        font-style: normal;
        font-weight: 400;
        font-size: $text-base;
        line-height: 1.9rem;

        color: $grey;

        &:not(:last-child) {
            margin-bottom: 0.8rem;

            @screen md {
                margin-bottom: 0;
            }
        }

        @screen md {
            line-height: 2.2rem;
            margin-bottom: 0;
        }
    }
}
