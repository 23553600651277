@import '@jaramba-frontend/core/styles/mixins';
@import '@jaramba-frontend/core/styles/variables';

.label {
    display: flex;
    flex-direction: column;
}

.wrapper {
    display: flex;
    align-items: center;
}

.input {
    position: absolute;

    width: 0.1rem;
    height: 0.1rem;
    margin: -0.1rem;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(50%);

    &:disabled ~ .checkbox {
        opacity: 0.3;
    }
}

.input ~ .checkbox > * {
    opacity: 0;
}

.input:checked ~ .checkbox {
    > * {
        opacity: 1;
    }

    &.isRound {
        background-color: $grey;
    }
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.6rem;
    min-width: 1.6rem;
    height: 1.6rem;

    border: solid 1px $grey;
    cursor: pointer;

    > svg path {
        fill: $grey;
    }
}

.withError {
    border: solid 1px $bright-red;
}

.isRound {
    border-radius: 50%;

    > svg > path {
        fill: $white;
    }
}

.text {
    font-size: $text-base;
    line-height: 1.8rem;
    margin-left: 0.8rem;

    a {
        @include link;
    }
}

.error {
    margin-top: 1rem;

    color: $bright-red;
    font-size: $text-base;
    line-height: 1;
    letter-spacing: 0.18px;
}
