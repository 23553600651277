@import '@jaramba-frontend/core/styles/variables';

.card {
    @apply flex flex-col;
    background-color: $white;

    box-shadow: 0 0 4px rgba(0, 0, 0, 0.16);
    border-radius: 20px;

    @screen md {
        height: fit-content;
        margin-top: auto;
        margin-bottom: 0;
    }
}

.cardHeader {
    padding: 0.6rem 1.2rem;
    border-radius: 20px 20px 0 0;
    background-color: $deep-red;
    position: relative;

    .text {
        font-family: MikadoBold;
        font-size: $text-base;
        font-weight: 700;
        line-height: 2.4rem;
        letter-spacing: 0.032rem;
        text-transform: uppercase;
        text-align: center;
        color: $white;
    }

    .savingPercentLabel {
        position: absolute;
        padding: 0.4rem 1.6rem;
        border-radius: 8px;
        background-color: #1cb27a;
        color: $white;
        top: -2.4rem;
        left: -0.8rem;
        transform: rotate(-6.45deg);

        @screen md {
            top: -2.9rem;
            left: -1.6rem;
            transform: rotate(-7deg);
        }

        &::before {
            content: '';
            position: absolute;
            bottom: -1rem;
            left: 50%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 1.5rem 1.5rem 1.5rem;
            border-color: transparent transparent #1cb27a transparent;
            transform: translateX(-50%) rotate(180deg);
        }

        & > span {
            text-align: center;
            font-family: MikadoBold;
            font-size: $text-base;
            font-weight: 700;
            line-height: 2.4rem;
        }
    }
}

.cardBody {
    padding: 1.6rem;

    @screen md {
        padding: 2.4rem;
    }
}

.planDetails {
    display: flex;
    gap: 0 1rem;
    justify-content: space-between;

    .billing {
        font-weight: 700;
        line-height: 3.2rem;
        text-transform: capitalize;
        font-size: 2.2rem;
    }

    .offerText {
        font-family: MikadoMedium;
        font-size: $text-large;
        font-weight: 500;

        line-height: 2.6rem;
        margin-top: 0.8rem;
    }
}

.priceContainer {
    grid-area: 1 / 2 / 3 / 3;
    display: flex;
    gap: 0.4rem;
    line-height: normal;

    .amount {
        font-family: MikadoBold;
        font-size: 4.8rem;
        line-height: 1;
        font-weight: 700;

        @screen sm {
            font-size: 6rem;
            line-height: 6rem;
        }

        @screen lg {
            font-size: 7rem;
            line-height: 7rem;
        }
    }

    .currency {
        display: block;
        font-family: MikadoMedium;
        font-size: $text-base;
        line-height: 2.4rem;
        font-weight: 500;
        text-transform: uppercase;

        @screen md {
            font-size: $text-increased;
            line-height: 2.6rem;
        }
    }

    .billing {
        font-size: $text-base;
        line-height: 2rem;
        font-weight: 400;
        opacity: 0.6;
        text-transform: lowercase;

        @screen md {
            font-size: $text-increased;
            line-height: 2.6rem;
        }
    }
}

.billingDetails {
    color: $primary;
    font-size: $text-base;
    font-weight: 400;
    line-height: 2.4rem;

    @screen md {
        margin-top: 1.6rem;
    }
}

.button {
    margin: 1.6rem 0 0;
}
