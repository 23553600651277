@import '@jaramba-frontend/core/styles/variables';

.label {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-of-type) {
        margin-bottom: 2.4rem;
    }
}

.wrapper {
    position: relative;

    width: 100%;
}

.input {
    width: 100%;
    padding: 1.6rem;

    color: $grey;
    font-size: $text-base;
    line-height: 1;
    letter-spacing: 0.18px;

    border: 1px solid rgba(27, 36, 36, 0.6);
    border-radius: 5px;

    &::placeholder {
        opacity: 0.6;
    }

    &.withValue,
    &:focus {
        border: 1px solid $primary;
        outline: none;
    }

    &.withError {
        border: 1px solid $bright-red;
    }

    &:disabled {
        opacity: 0.5;

        & ~ .icon {
            opacity: 0.3;
            cursor: default;
        }
    }
}

.icon {
    width: 2.7rem;
    height: 2.7rem;
    position: absolute;
    top: 50%;
    right: 1.6rem;

    padding: 0.5rem;

    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
    background-color: $white;

    path {
        fill: $grey;
    }
}

.labelText {
    margin-bottom: 0.8rem;

    color: $grey;
    font-weight: 400;
    font-size: $text-base;
    line-height: 1;
}

.error {
    margin-top: 1rem;

    color: $bright-red;
    font-weight: 400;
    font-size: $text-base;
    line-height: 1;
    letter-spacing: 0.18px;
}
