/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Mikado Regular by HVD Fonts
 * URL: https://www.myfonts.com/products/regular-mikado-399136
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Mikado Medium by HVD Fonts
 * URL: https://www.myfonts.com/products/medium-mikado-399153
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Mikado Bold by HVD Fonts
 * URL: https://www.myfonts.com/products/bold-mikado-399166
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Mikado Black by HVD Fonts
 * URL: https://www.myfonts.com/products/black-mikado-399184
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */

@font-face {
    font-family: 'MikadoRegular';
    src: url('../assets/fonts/MikadoRegular/font.woff2') format('woff2'),
        url('../assets/fonts/MikadoRegular/font.woff2') format('woff');
}
@font-face {
    font-family: 'MikadoBold';
    src: url('../assets/fonts/MikadoBold/font.woff2') format('woff2'),
        url('../assets/fonts/MikadoBold/font.woff') format('woff');
}
@font-face {
    font-family: 'MikadoBlack';
    src: url('../assets/fonts/MikadoBlack/font.woff2') format('woff2'),
        url('../assets/fonts/MikadoBlack/font.woff') format('woff');
}
@font-face {
    font-family: 'MikadoMedium';
    src: url('../assets/fonts/MikadoMedium/font.woff2') format('woff2'),
        url('../assets/fonts/MikadoMedium/font.woff') format('woff');
}
