// Brand colors
$grey: #424242;
$beige: #fff3e8;
$blue: #c0dbe0;
$green: #294b4b;
$deep-red: #6f1f3a;
$orange: #ff6700;
$bright-green: #00a076;
$white: #ffffff;
$bright-red: #e72d0b;

$primary: $orange;

/* Document colors */
$document-background: $beige;

/* Navbar */
$navbar-background-color: $white;
$navbar-dropdown-background-color: $beige;
$navbar-link-color: $grey;
