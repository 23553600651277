@import '@jaramba-frontend/core/styles/variables';

.navbarMenu {
    position: absolute;
    right: 0;
    left: 0;
    padding-top: $headerHeightMobile;
    padding-bottom: 2.5rem;
    background-color: $white;
    transform: translate3d(0, 0, 0);
    transition: top 0.37s cubic-bezier(0.47, 1.64, 0.41, 0.9);
    z-index: -1;

    @screen md {
        position: initial;
        top: 0;
        padding-top: 0;
        padding-bottom: 0;
        background-color: $white;
        margin-left: 5.4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: $headerHeightMobile;
        width: 100%;
    }

    &.show {
        top: 0;
        box-shadow: 0px 14px 17px -6px rgba(0, 0, 0, 0.3);

        @screen md {
            box-shadow: none;
        }
    }

    &.hide {
        top: -35rem;
    }
}

.noScroll {
    overflow: hidden;
}
