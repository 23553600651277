@import '@jaramba-frontend/core/styles/variables';

.container {
    width: fit-content;
    display: flex;
    margin: 5rem auto;
}

.dot {
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%;
    margin: 0 0.3rem;
    background-color: $primary;
    transform: scale(0);

    &:first-child {
        z-index: 1;
        animation: grow 3s infinite ease-in-out;
    }

    &:nth-child(2) {
        animation: grow 3s infinite ease-in-out;
        animation-delay: 0.3s;
    }

    &:last-child {
        animation: grow 3s infinite ease-in-out;
        animation-delay: 0.6s;
    }
}

@keyframes grow {
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1);
    }
    50% {
        transform: scale(0);
    }
    75% {
        transform: scale(1);
        background-color: $primary;
        opacity: 0.8;
    }
    100% {
        transform: scale(0);
        background-color: $primary;
        opacity: 1;
    }
}
