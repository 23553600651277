@import '@jaramba-frontend/core/styles/variables';

$toggleSize: 2.2rem;
$toggleButtonOffset: 0.4rem;

.switchWrapper {
    position: relative;

    float: left;
    width: 5rem;
    min-width: 5rem;
    overflow: hidden;

    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.checked::before {
        background-color: $primary;
    }

    &::before {
        position: absolute;
        top: 50%;

        width: 100%;
        height: 2.8rem;

        background-color: #c1c1c1;
        transition: background-color 0.2s;
        border-radius: 2rem;
        transform: translateY(-50%);

        content: '';
    }

    > input[type='checkbox'] {
        position: absolute;

        opacity: 0;

        + .switch {
            transform: translateX($toggleButtonOffset);
        }

        &:checked + .switch {
            transform: translateX(50%) translateX(-$toggleSize - $toggleButtonOffset);
        }
    }

    > .switch {
        position: relative;
        left: -100%;

        display: inline-block;
        width: 200%;
        height: 100%;

        transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        > .switchHandle {
            position: relative;
            top: 50%;
            left: 50%;

            display: inline-block;
            width: $toggleSize;
            height: $toggleSize;

            background-color: $white;
            border-radius: 50%;
            transform: translateY(15%);
        }
    }
}
