@import '../assets/mikado-font';

.MikadoRegular {
    font-family: MikadoRegular;
}

.MikadoMedium {
    font-family: MikadoMedium;
}

.MikadoBold {
    font-family: MikadoBold;
}
.MikadoBlack {
    font-family: MikadoBlack;
}

/* Font sizes */
$title-large: 6.4rem;
$title: 4rem;
$subtitle-large: 3.2rem;
$subtitle: 2.4rem;
$text-large: 2rem;
$text-increased: 1.8rem;
$text-base: 1.6rem;
$text-small: 1.4rem;
$text-smaller: 1.2rem;
