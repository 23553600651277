@import '@jaramba-frontend/core/styles/variables';

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContent {
    max-width: 40rem;
    position: relative;
    outline: none;
}

.closeButton {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;

    & img {
        width: 1.3rem;
        height: 1.3rem;
    }
}

.bodyScrollLock {
    overflow: hidden;
    touch-action: none;
}
