@import '@jaramba-frontend/core/styles/variables';

.pageContent {
    width: 100%;
    padding: 0 2rem;
}

.paymentCard {
    max-width: 64.2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem auto;
    padding: 2.6rem;
}

.title {
    font-size: $subtitle;
    text-align: center;
    line-height: 2.8rem;
    padding: 0;

    @screen md {
        font-size: $subtitle-large;
        line-height: 4.8rem;
    }
}

.infoCard {
    font-size: $text-base;
    border-radius: 20px;
    background-color: $blue;
    padding: 1rem 1.5rem;
    margin: 1.5rem 0 1rem;

    & > p {
        font-size: $text-base;
        line-height: 2.4rem;

        @screen md {
            font-size: $text-increased;
        }
    }

    .subtitle {
        font-weight: 700;
    }
}

.errorMessage {
    text-align: center;
    color: $bright-red;
    font-size: $text-base;
    line-height: 1;
    letter-spacing: 0.18px;
    margin-top: 2rem;
}

.loading {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
