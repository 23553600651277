@import '@jaramba-frontend/core/styles/variables';

.root {
    width: auto;
    height: 2.6rem;

    @screen md {
        height: 3rem;
    }
}
