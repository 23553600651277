@import '@jaramba-frontend/core/styles/variables';

.pageContent {
    width: 100%;
    text-align: center;
    padding: 2.7rem 2rem;

    @screen md {
        padding: 7rem 2rem;
    }
}

.title {
    font-weight: 700;
    font-size: 5.6rem;
    color: $grey;
    margin-bottom: 1rem;

    @screen md {
        margin-bottom: 3.5rem;
    }
}

.subtitle {
    font-weight: 400;
    font-size: $text-large;
    line-height: 2.8rem;
    letter-spacing: 0.05px;
    color: $grey;
}

.qrBanner {
    display: block;
    max-height: 35.4rem;
    height: 100%;

    margin: 3rem auto;
}

.mobileOS {
    font-weight: 600;
    font-size: $text-base;
    line-height: 2.8rem;

    letter-spacing: 0.05px;

    color: #4c4b5d;
}

.storeButtons {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    flex-wrap: wrap;
    margin-bottom: 4.8rem;

    .storeButton > svg {
        height: 4.6rem;
    }

    & > .storeButton {
        margin: 0.3rem 0.6rem;
    }
}
