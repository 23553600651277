@import '@jaramba-frontend/core/styles/variables';

.container {
    max-width: 64.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem 2.4rem;

    margin: 0 auto;
    margin-top: 5rem;

    @screen md {
        flex-direction: row;
    }
}

.content {
    text-align: center;

    & > *:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    @screen md {
        text-align: left;
    }
}

.title,
.price,
.offer {
    font-weight: 700;
    font-size: $text-base;
    line-height: 1.9rem;
    color: $grey;

    @screen md {
        line-height: 2.9rem;
    }
}

.buttonLink {
    font-family: 'MikadoBold';
    font-weight: 700;
    font-size: $text-base;
    line-height: 1.9rem;
    text-align: center;
    color: $primary;
    margin-top: 1rem;

    @screen md {
        line-height: 2.2rem;
        margin-top: 0;
    }
}
