@import '@jaramba-frontend/core/styles/mixins';
@import '@jaramba-frontend/core/styles/variables';

$consentBannerIndex: 999;

.consentBanner {
    width: 100%;
    max-width: 55rem;
    position: fixed;
    right: 0;
    bottom: 2rem;
    left: 0;
    z-index: $consentBannerIndex;

    margin: 0 auto;
    padding-inline: 1rem;
    outline: none;

    @screen md {
        bottom: 3.3rem;
    }

    .paper {
        padding: 1.6rem;
        background-color: $white;
        border-radius: 2rem;
        box-shadow: 0 0 105px 40px rgba(0, 0, 0, 0.2);

        @screen md {
            padding: 2rem 2.3rem;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;

        .cookieEmoji {
            width: 2.4rem;
            min-width: 2.4rem;
            height: 2.4rem;
            min-height: 2.4rem;
            margin-right: 1rem;
            line-height: normal;
            text-align: center;

            border: 0.2rem solid #5050681a;
            border-radius: 100%;
            cursor: default;
        }

        .text {
            font-size: 1.3rem;
            line-height: 1.9rem;
            letter-spacing: -0.167px;

            @screen md {
                margin: 0;
                padding: 0;

                font-size: $text-base;
                line-height: 2.4rem;
            }
        }
    }
}

.buttonsContainer {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.3rem;

    @media screen and (max-width: 400px) {
        flex-direction: column;
    }

    & > .button {
        width: 100%;
        max-width: none;
        padding: 0 1.2rem;
        margin: 0;

        font-size: $text-small;
        line-height: 1rem;

        @media screen and (max-width: 640px) {
            height: 3.5rem;
        }

        @screen sm {
            height: 4rem;
            padding: 0 2.4rem;

            font-size: $text-base;
            line-height: 1.8rem;
        }
    }
}

.modal {
    bottom: 0;

    width: 100%;
    max-width: 61rem;
    max-height: 90%;
    margin: 0;
    overflow: hidden;
    animation: slideUp 0.3s cubic-bezier(0.6, 0, 0.1, 1);

    @keyframes slideUp {
        0% {
            bottom: -100vh;
        }

        100% {
            bottom: 0;
        }
    }

    @screen sm {
        margin-inline: 1rem;
        animation: none;
    }

    .scrollContainer {
        overflow-y: auto;

        padding-top: 1.5rem;
        padding-inline: 2rem;

        @screen md {
            padding-block: 2rem;
            padding-inline: 4rem;
        }

        @screen sm {
            padding-inline: 3rem;
        }
    }

    .modalHeader {
        text-align: center;
    }

    .title {
        font-weight: 700;
        font-size: $subtitle;
    }

    .subtitle {
        line-height: 1.6;

        font-size: $text-small;
    }

    .buttonsContainer {
        background-color: $white;
        box-shadow: 0 0 30px 40px $white;
        position: relative;
        margin-top: 0;

        padding: 0 2rem 2rem;
    }

    .contentSettings {
        max-height: 60vh;
        margin-top: 1.5rem;

        @media screen and (max-height: 700px) {
            max-height: 30vh;
        }

        @screen md {
            margin-top: 3rem;
        }
    }

    .setting {
        &:last-child {
            padding-bottom: 4rem;
        }

        &:not(:last-child) {
            margin-bottom: 2rem;
            padding-bottom: 2rem;

            border-bottom: 0.1rem solid rgba(80, 80, 104, 0.2);
        }

        .nameContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .name {
                margin-right: 0.5rem;

                font-weight: bold;
                font-size: $text-base;
                line-height: 2.4rem;

                @screen md {
                    font-size: $text-increased;
                }
            }

            .disabledSetting {
                opacity: 1;
            }
        }

        .description {
            margin-top: 1rem;

            font-size: $text-small;
            line-height: 1.6;

            @screen md {
                margin-top: 1.4rem;
            }
        }
    }

    .modalCloseButton {
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
        padding: 1rem;

        @screen md {
            top: 0.6rem;
            right: 0.6rem;
        }

        & > img {
            width: 1.3rem;
            height: 1.3rem;
        }
    }
}

.modalOverlay {
    z-index: $consentBannerIndex;

    align-items: flex-end;

    @screen sm {
        align-items: center;
    }
}

.learnMoreLink {
    @include link;
}
