@mixin link() {
    color: $primary;

    cursor: pointer;

    text-decoration: underline;

    transition: opacity 0.1s ease;

    &:visited {
        color: $primary;
    }

    &:active,
    &:focus,
    &:hover {
        color: $primary;

        opacity: 0.8;
    }
}

@mixin parentPortalLinkColor() {
    color: $primary;
    opacity: 0.8;

    &:visited {
        color: $primary;
    }

    &:active,
    &:focus,
    &:hover {
        color: $primary;
        opacity: 0.6;
    }
}

@mixin gpuAcceleration() {
    transform: translate3d(0, 0, 0);
}
