@import '@jaramba-frontend/core/styles/variables';

.root {
    height: 100vh;
    display: grid;
    grid-template-rows: 7rem auto 10.4rem;

    .content {
        background-color: $beige;
        width: 100%;
        height: 100%;
    }
}
