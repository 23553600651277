@import '@jaramba-frontend/core/styles/variables';

.formContainer {
    max-width: 64.2rem;
    margin: 2rem auto;
    padding: 3.2rem 2rem 2.6rem;

    @screen md {
        padding: 3.5rem 11rem;
    }

    .title {
        font-weight: 700;
        font-size: $subtitle;
        line-height: 3.2rem;
        color: $grey;
        text-align: center;
        padding: 0;

        @screen md {
            font-size: $subtitle-large;
        }
    }

    .form {
        margin-top: 2.8rem;

        @screen md {
            margin-top: 4rem;
        }
    }

    .responseErrorsContainer {
        margin: 2rem 0;
        margin-left: 1.5rem;
        list-style-type: disc;

        .responseError {
            color: $bright-red;
        }
    }

    .submitButton {
        margin-top: 2.8rem;

        @screen md {
            margin-top: 3rem;
        }
    }
}

.loadingContainer {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}
